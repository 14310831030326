import { useEffect, useState } from 'react'

export function useSliderInterval() {
  const [slideNumber, setSlideNumber] = useState(0)
  const [playSlider, setPlaySlider] = useState(true)

  useEffect(() => {
    if (playSlider) {
      const intervalId = setInterval(() => {
        setSlideNumber(prevNumber => {
          if (prevNumber === 2) {
            return 0
          }
          return prevNumber + 1
        })
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [playSlider])

  return {
    slideNumber,
    setSlideNumber,
    setPlaySlider
  }
}
